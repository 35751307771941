
.article-bullet-point {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 24px;
}

.context-article-bold-paragraph {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-left: 0px;
}

#sections-image {
    width: 600px;
    margin-bottom: 40px;
}

#chunk-performance-image {
    width: 500px;
    margin-bottom: 20px;
}

#full-rse-image {
    width: 800px;
    margin-bottom: 10px;
}

.context-article-link {
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    color: #0077ff;
}

.context-blog-image {
    max-width: 900px;
}

@media screen and (max-width: 1200px) {

    .article-header-row {
        padding: 10px 20px;
    }

    #sections-image {
        width: 600px;
        margin-bottom: 40px;
    }
    
    #chunk-performance-image {
        width: 500px;
        margin-bottom: 20px;
    }

}


@media screen and (max-width: 820px) {

    .context-blog-image, #sections-image, #chunk-performance-image, #full-rse-image {
        width: 90%;
    }

}
