
* {
    box-sizing: border-box;
}

.home-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /*background: linear-gradient(#1a1a1a, #2c2c2c);*/
}

.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 15px;
    padding-bottom: 120px;
    padding-left: 0px;
    padding-right: 0px;
}

.homepage-main-content-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
}

.homepage-main-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
}

.homepage-graphic-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
    margin-bottom: 0px;
}

.homepage-graphic-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 24px;
    color: #2D2D2D;
    margin-top: 20px;
    text-align: center;
}

.homepage-graphic-d-star-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    border: 1px solid #BCBCBC;
    border-radius: 20px;
    padding: 30px 60px;
}

.homepage-graphic-d-star-text {
    font-family: "Barlow";
    font-weight: 600;
    font-size: 60px;
    line-height: 60px;
    margin: 0px;
}

.homepage-graphic-arrow {
    width: 50px;
}

#arrow-right {
    margin-left: 15px;
}

#arrow-both {
    margin-right: 15px;
}

.feature-row {
    display: flex;
    flex-direction: column;
    max-width: 475px;
    margin: auto;
    margin-top: 50px;
}

.feature-header {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 32px;
    color: #2D2D2D;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.feature-text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2D2D2D;
    margin-top: 10px;
    text-align: center;
}

.contact-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 55px;
    border: 1px solid #2D2D2D;
    border-radius: 7px;
    width: 175px;
    padding: 10px 40px;
    cursor: pointer;
}

.contact-button-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
    padding: 0px
}


/* Media Queries */

@media screen and (max-width: 1000px) {
    
    .home-container {
        max-width: 750px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .homepage-main-text {
        font-size: 34px;
    }
    
}


@media screen and (max-width: 750px) {

    .home-container {
        max-width: 95%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .homepage-main-text {
        font-size: 30px;
    }

    .homepage-graphic-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 80px;
    }

    .homepage-graphic-arrow {
        width: 40px;
    }

    #arrow-right {
        margin-left: 0px;
    }
    
    #arrow-both {
        margin-right: 0px;
    }

    #arrow-right, #arrow-both {
        /* rotate 90 degrees */
        transform: rotate(90deg);
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .feature-header {
        font-size: 26px;
    }
    
    .feature-text {
        font-size: 15px;
        line-height: 19px;
    }
    
}


@media screen and (max-width: 550px) {

    .home-container {
        margin-top: 20px;
        max-width: 100%;
    }

    .homepage-main-text {
        font-size: 26px;
    }

    .homepage-graphic-d-star-wrapper {
        margin: 20px;
        border-radius: 20px;
        padding: 25px 50px;
    }
    
    .homepage-graphic-d-star-text {
        font-family: "Barlow";
        font-weight: 600;
        font-size: 50px;
        line-height: 50px;
        margin: 0px;
    }

    .homepage-graphic-text {
        font-size: 22px;
    }

}