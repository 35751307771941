
.pricing-page-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.pricing-page-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
    padding-bottom: 100px;
}

.main-pricing-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 48px;
    color: #383838;
    margin-top: 15px;
    margin-bottom: 40px;
    text-align: center;
}

.pricing-details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.pricing-details-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 375px;
}

.pricing-option-header {
    font-family: "Playfair Display", serif;
    font-weight: 300;
    font-size: 32px;
    color: #383838;
    margin-bottom: 10px;
}

.pricing-detail-text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: #383838;
    margin-bottom: 5px;
    padding-left: 10px;
}

li, ul {
    margin-left: 12px;
    padding-left: 0px;
}

.pricing-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
    border: 1px solid #2D2D2D;
    border-radius: 7px;
    width: 175px;
    padding: 10px 40px;
    cursor: pointer;
}

.pricing-button-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
    padding: 0px
}


@media screen and (max-width: 850px) {

    .main-pricing-text {
        font-size: 42px;
        margin-top: 15px;
        margin-bottom: 40px;
    }

    .pricing-option-header {
        font-size: 28px;
        margin-bottom: 10px;
    }
    
    .pricing-detail-text {
        font-size: 15px;
        margin-bottom: 5px;
        padding-left: 10px;
    }

    .pricing-page-container {
        max-width: 100%;
        margin-top: 10px;
        padding-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .pricing-details-col {
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%;
    }

}


@media screen and (max-width: 600px) {

    .pricing-details-row {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .pricing-details-col {
        margin-bottom: 50px;
    }

}