
.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 80px;
    background-color: #2D2D2D;
    color: #fafafa;
}

.footer-text {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 14px;
    color: #fafafa;
}


@media screen and (max-width: 750px) {

    .footer-row {
        padding: 15px 50px;
    }

}

@media screen and (max-width: 550px) {

    .footer-row {
        padding: 15px 30px;
    }

}