
.rse-article-bold-paragraph {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
}

.rse-bold-header {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #383838;
    margin-top: 30px;
}

.rse-examples-container {
    margin-bottom: 35px;
}

.rse-example-text {
    padding-left: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.article-list-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.article-sub-list-item {
    margin-left: 30px;
}

.rse-article-image {
    width: 100%;
    max-width: 700px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.table-container {
    border: 1px solid #383838;
    margin-bottom: 20px;
}

.table-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #383838;
}

.table-row-4 {
    border-bottom: none;
}

.table-cell {
    display: flex;
    flex: 1;
    border-right: 1px solid #383838;
    padding: 5px;
}

.table-cell-0 {
    display: flex;
    flex: 2;
}

.table-cell-4 {
    border-right: none;
}

.table-header-text {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #383838;
    margin-top: 0px;
    margin-bottom: 0px;
}

.table-text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: #383838;
    margin-top: 0px;
    margin-bottom: 0px;
}

.table-text-4-0 {
    font-weight: 700;
}

@media screen and (max-width: 720px) {

    .table-text, .table-header-text {
        font-size: 14px;
    }

}

.code-text {
    /* Use the SF Mono font */
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
    font-size: 14px;
    background-color: #efefef;
    padding: 2px 9px;
    border-radius: 7px;
}