
.home-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 80px;
}

.header-links-col {
    display: flex;
    flex-direction: row;
}

.d-star-logo-light, .d-star-logo-dark {
    font-family: "Barlow";
    font-weight: 600;
    font-size: 60px;
    color: #fafafa;
    margin-top: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
    cursor: pointer;
}

.d-star-logo-dark {
    color: #383838;
}

.header-link-text-light, .header-link-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    color: #fafafa;
    cursor: pointer;
    margin-left: 50px;
    margin-right: 0px;
}

.header-link-text-light:hover, .header-link-text-dark:hover {
    font-weight: 700;
}

.header-link-text-dark {
    color: #383838;
}

.github-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0px;
}

.hamburger-menu-container {
    display: none;
}


/* Media Queries */

@media screen and (max-width: 1000px) {
    
    .home-header-row {
        padding: 10px 50px;
    }
    
}

@media screen and (max-width: 750px) {

    .d-star-logo {
        font-size: 50px;
        margin-top: 20px;
    }
    
    .home-header-row {
        padding: 10px 30px;
    }

    .header-link-text-light, .header-link-text-dark {
        margin-left: 40px;
        font-size: 15px;
    }
    
}

@media screen and (max-width: 550px) {

    .d-star-logo {
        font-size: 44px;
        margin-top: 15px;
    }
    
    .home-header-row {
        padding: 10px 20px;
    }

    .header-link-text-light, .header-link-text-dark {
        margin-left: 25px;
    }

    .hamburger-menu-container {
        display: none;
    }

    .hamburger-menu-icon {
        font-size: 24px;
        color: #2d2d2d;
        cursor: pointer;
    }

    .github-link-container {
        display: none;
    }
    
}