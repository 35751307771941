
.article-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.article-header-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 80px;
}

.article-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
    padding-bottom: 80px;
}

.article-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.article-title-text {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-size: 32px;
    color: #383838;
    margin-bottom: 35px;
    text-align: center;
}

.article-name-text, .article-date-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 15px;
    color: #383838;
    margin-top: 5px;
    margin-bottom: 5px;
}

.article-date-text {
    font-weight: 300;
}

.article-paragraph {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 35px;
}

.indented-paragraph {
    padding-left: 40px;
}

.article-header-text {
    font-size: 24px;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    color: #383838;
    margin-top: 30px;
}

.article-bold-paragraph {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-left: 40px;
}

#sp-rag-link {
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline;
    color: #0077ff;
}

iframe {
    width: 750px;
    height: calc(750px * 0.5625);
}

#email-link {
    font-family: "Inter";
    font-weight: 400;
    color: #0077ff;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {

    .article-header-row {
        padding: 10px 20px;
    }

    .article-container {
        padding: 0px 20px;
        padding-bottom: 80px;
    }

    iframe {
        width: 650px;
        height: calc(650px * 0.5625);
    }

}


@media screen and (max-width: 720px) {

    .article-paragraph, .article-bold-paragraph {
        font-size: 15px;
        line-height: 22px;
    }

    .article-bold-paragraph, .indented-paragraph {
        padding-left: 20px;
    }

    iframe {
        width: 85vw;
        height: calc(85vw * 0.5625);
    }

    .d-star-logo-light {
        font-size: 50px;
        margin-top: 20px;
    }

}