
.blogs-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.blogs-main-container {
    display: flex;
    min-height: calc(100vh - 100px);
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
}

.blogs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
    padding: 0px 25px;
}

.blogs-list {
    display: flex;
    flex-direction: column;
}

.blog-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 20px;
}

.blog-title {
    font-family: "Playfair Display", serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.blog-subtitle {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
}

.blog-name-date-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.blog-name {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 0px;
}

.blog-date {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;
}